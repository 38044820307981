import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { SolutionLayout } from "../components/solutions-layout"

export default function HospitalManagement() {
  return (
    <Layout tcoreinsight={false}>
      <SEO
        title="Online Hospital management software | Touchcore Technology Nigeria"
        description="HospitalPro is a Cloud-based Hospital Management Software that automates your day to day running processes seamlessly"
      />
      <SolutionLayout
        data={{
          slug: "hospital-pro",
          hero: {
            image: require("./../assets/hospital-screen.png"),
            bgColor: "bg-white text-ppurple",
            titleSecond: "HospitalPro",
            subtitle: "Cloud based hospital management software",
            content:
              "Whether your hospital is private, public, small or big, Touchcore offers packages that meet all of your data management requirements.Our unique, integrated modular design creates a seamless flow of data throughout your hospital and ensures that you only purchase what you need.",
          },
          section: {
            title: "How can HospitalPro help you?",
            subtitle:
              "We offer industry standard solutions which not only streamlines the whole processes but also increases efficiency and saves you a lot of cash",
          },
          imageSection1: {
            image: require("./../assets/hospital-patient-screen.png"),
            overlap: true,
            title: "Enhance your patient experience",
            content:
              "Onboard patients, schedule doctor’s appointments, manage lab results and prescriptions, manage doctors and nurses, send payment requests and run your hospital from anywhere using HospitalPro.",
          },
          imageSection2: {
            image: require("./../assets/hms-screen1.png"),
            title: "Manage your process from a simple to use dashboard",
            content:
              "Manage all your day to day processes from a simple easy to use dashboard. Admit patients, book appointments, manage patient records and payments.",
          },
        }}
      />
    </Layout>
  )
}
